<template>
  <div class="main_content">
    <div class="img_box">
      <div class="bg"></div>
      <img src="../../assets/img/changhai/home.png" class="logo" />
    </div>
    <div class="p1">正在前往长海医院</div>
    <div class="p2">智慧服务对象：{{ patient_name }}</div>
    <div class="p3">
      如您已绑定多张就诊卡<br />智慧服务对象可能为非本人，请核实信息
    </div>
  </div>
</template>

<script>
import { get_patient_info } from "../article/service"
import { get_url_params } from "../../utils/format"

export default {
  data() {
    return {
      patient_name: "",
    }
  },
  computed: {},
  components: {},
  created() {
    this.init_data()
    setTimeout(() => {
      const _redirect = this.$route.query.redirect
      let redirect = decodeURIComponent(_redirect)
      /* eslint-disable */
      if (
        _redirect &&
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+/.test(
          redirect
        )
      ) {
        if (this.$route.query.msg_id) {
          const [url] = redirect.split("?")
          const query = get_url_params(redirect)

          if (query.nextUrl && !query.nextUrl.includes("dataSource")) {
            let nextUrl = decodeURIComponent(query.nextUrl)
            if (nextUrl.includes("?")) {
              nextUrl = nextUrl + "&dataSource=hh_" + this.$route.query.msg_id
            } else {
              nextUrl = nextUrl + "?dataSource=hh_" + this.$route.query.msg_id
            }
            query.nextUrl = encodeURIComponent(nextUrl)
          }
          redirect =
            url +
            "?" +
            Object.keys(query)
              .map((el) => `${el}=${query[el]}`)
              .join("&")
        }
        location.replace(redirect)
      }
    }, 3000)
  },
  methods: {
    async init_data() {
      const { data } = await get_patient_info({
        msg_id: this.$route.query.msg_id,
      })
      this.patient_name = data.patient_name
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  & .p1 {
    font-size: 15px;
    font-weight: 500;
    color: #6e6e6e;
    line-height: 20px;
    margin-top: 15px;
  }
  & .p2 {
    font-size: 18px;
    font-weight: 500;
    color: #1e252f;
    margin-top: 14px;
  }
  & .p3 {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    margin-top: 14px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img_box {
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & .logo {
    width: 48px;
    height: 48px;
  }
  & .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    animation: rotate 4s infinite linear;
    &:before {
      display: block;
      content: "";
      width: 3px;
      height: 3px;
      background-color: #3dcb69;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-2px, -50%);
    }
  }
}
</style>
